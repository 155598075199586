<template>
  <div>
    <KTCard title="User Profile">
      <template v-slot:toolbar> </template>

      <template v-slot:body>
        <pre class="d-none">{{ UserData }}</pre>
        <b-row>
          <b-col cols="2" class="text-right">
            <label class="font-weight-bold">Username</label>
          </b-col>
          <b-col>{{ UserData.username }}</b-col>
        </b-row>
        <b-row>
          <b-col cols="2" class="text-right">
            <label class="font-weight-bold">Email</label>
          </b-col>
          <b-col>{{ UserData.email }}</b-col>
        </b-row>
        <b-row v-if="UserData.company">
          <b-col cols="2" class="text-right">
            <label class="font-weight-bold">Company Name</label>
          </b-col>
          <b-col>{{ UserData.company.company_name }}</b-col>
        </b-row>
        <b-row>
          <b-col cols="2" class="text-right">
            <label class="font-weight-bold">Last Login</label>
          </b-col>
          <b-col>{{ UserData.last_login }}</b-col>
        </b-row>
      </template>
    </KTCard>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/modules/breadcrumbs.module";
import UserService from "@/core/services/user.service";

export default {
  name: "blank",
  components: {},
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "User Profile" }]);
  },
  data() {
    return {};
  },

  methods: {},
  computed: {
    UserData() {
      let userData = JSON.parse(UserService.getUserData());
      return userData;
    },
  },
};
</script>
